import { AxiosRequestConfigWithRetry, axiosInstance } from '@nx/fetch'
import { NotFoundError, httpToHttps } from '@nx/helpers'

import { AuctionResponse, RawAuctionResponse } from '../types'

export async function getRawAuction(
  baseURL: string,
  auctionId: string,
  options?: AxiosRequestConfigWithRetry
) {
  if (!/^\d+$/.test(auctionId)) {
    throw new NotFoundError('auctionId must be an integer')
  }

  const url = `/api/v2/sale/${auctionId}/`

  const result = await axiosInstance.get<RawAuctionResponse>(url, {
    baseURL,
    ...options,
  })

  return result.data
}

export async function getAuction(
  baseURL: string,
  auctionId: string,
  options?: AxiosRequestConfigWithRetry
): Promise<AuctionResponse> {
  const result = await getRawAuction(baseURL, auctionId, options)

  if (result.catalogue.image_url) {
    result.catalogue.image_url = httpToHttps(result.catalogue.image_url)
  }

  if (result.conditions_of_sale.image_url) {
    result.conditions_of_sale.image_url = httpToHttps(
      result.conditions_of_sale.image_url
    )
  }

  return {
    ...result,
    related_headline: result.related_headline.map(
      ({ sBody, sHeadlineStyled, sPath, ...rest }) => rest
    ),
  }
}
