import { MouseEventHandler, forwardRef, memo } from 'react'
import isEqual from 'react-fast-compare'
import styled from 'styled-components'

import { FireLink } from '@nx/fire/link'
import { LotSymbol } from '@nx/global-types'
import {
  EAuctionBiddingEngine,
  EAuctionType,
  ELotStatus,
  ImageValue,
  LegacyImageValue,
} from '@nx/global-types'
import { SmartlingAttributes } from '@nx/smartling'

import {
  LotCardCompactContainer,
  LotCardCompactMainContainer,
  StyledLotCardSeparator,
} from './LotCard.styles'
import LotCardImage from './components/LotCardImage'
import { LotCardPrice } from './components/LotCardPrice'
import LotCardTitle from './components/LotCardTitle'
import { StyledLotCardTitleContainer } from './components/LotCardTitle.styles'

export const LotCardCompact = memo(
  forwardRef<HTMLDivElement, LotCardCompactProps>(
    (
      {
        image,
        lotItemId,
        lotIdNumber,
        lotId,
        biddingEngine,
        symbols,
        title,
        heading,
        status,
        isResultPublished,
        isPreview,
        isExhibition,
        isReferToDepartment,
        isWithoutReserve,
        currencySymbol,
        currencyISO,
        hammerPrice,
        hammerPremium,
        estimateLow,
        estimateHigh,
        GBPLowEstimate,
        GBPHighEstimate,
        auctionType,
        isShowingLotId,
        lotClickCallback,
        isUserPurchase,
        href,
        showPrice,
        ...props
      },
      ref
    ) => {
      return (
        <LotCardCompactMainContainer ref={ref} {...props}>
          <LotCardCompactContainer>
            <LotCardImage
              {...SmartlingAttributes.noTranslate}
              image={image}
              lotItemId={lotItemId}
              title={title}
              href={href}
              lotClickCallback={lotClickCallback}
              isFollowButtonDisplayed={false}
              biddingEngine={biddingEngine}
              size="S"
            />
            <div>
              <FireLink href={href} onClick={lotClickCallback}>
                <StyledLotCardTitle
                  lotId={lotId}
                  symbols={symbols}
                  title={title}
                  heading={heading}
                  isShowingLotId={isShowingLotId}
                  biddingEngine={biddingEngine}
                  isExhibition={isExhibition}
                  isPreview={isPreview}
                />
                {showPrice && (
                  <StyledLotCardPrice
                    status={status}
                    auctionType={auctionType}
                    isResultPublished={isResultPublished}
                    isExhibition={isExhibition}
                    isReferToDepartment={isReferToDepartment}
                    currencySymbol={currencySymbol}
                    currencyISO={currencyISO}
                    hammerPrice={hammerPrice}
                    hammerPremium={hammerPremium}
                    estimateLow={estimateLow}
                    estimateHigh={estimateHigh}
                    GBPLowEstimate={GBPLowEstimate}
                    GBPHighEstimate={GBPHighEstimate}
                    highlightWon={isUserPurchase}
                  />
                )}
              </FireLink>
            </div>
          </LotCardCompactContainer>
          <StyledLotCardCompactSeparator />
        </LotCardCompactMainContainer>
      )
    }
  ),
  isEqual // deep comparison of props
)

export interface LotCardCompactProps {
  image: LegacyImageValue | ImageValue | null
  lotItemId: number | string
  lotIdNumber: number
  lotId: string
  symbols: LotSymbol[]
  biddingEngine: EAuctionBiddingEngine
  title: string
  heading: string
  status: ELotStatus
  isResultPublished: boolean
  isExhibition: boolean
  isPreview: boolean
  isReferToDepartment: boolean
  isWithoutReserve: boolean
  currencySymbol: string
  currencyISO: string
  hammerPrice: number
  hammerPremium: number
  estimateLow: number
  estimateHigh: number
  GBPLowEstimate: number
  GBPHighEstimate: number
  auctionType: EAuctionType
  lotClickCallback?: MouseEventHandler<HTMLAnchorElement>
  isShowingLotId: boolean
  isUserPurchase?: boolean
  href: string
  showPrice?: boolean
}

const StyledLotCardPrice = styled(LotCardPrice)`
  margin-top: 0;
`

const StyledLotCardTitle = styled(LotCardTitle)`
  ${StyledLotCardTitleContainer} {
    ${({ theme }) => theme.typography.body.M}
  }
`

const StyledLotCardCompactSeparator = styled(StyledLotCardSeparator)`
  display: block;
  margin-bottom: 10px;
  margin-left: 0;
  margin-top: 10px;
`
