import { useQuery } from '@tanstack/react-query'

import { CheckUserLoginStatusReturn } from '@nx/auth'

import { getUserRegistrations } from './getUserRegistrations'

export function useGetUserRegistrations({
  clientNo,
  baseUrl,
}: UseUserRegistrationsProps) {
  const { data, status, error } = useQuery({
    queryKey: ['userRegistrations', { clientNo }],
    queryFn: async () => {
      const response = await getUserRegistrations(clientNo, baseUrl)

      if (response) {
        return response
      } else {
        throw new Error('User has no registrations')
      }
    },
    retry: 1,
    enabled: Boolean(clientNo),
    refetchOnWindowFocus: true,
    staleTime: 300000, // 5 minutes.
  })

  return {
    userRegistrations: data || [],
    status,
    error,
  }
}

export interface UseUserRegistrationsProps {
  clientNo: CheckUserLoginStatusReturn['user']['iClientNo']
  baseUrl: string
}
