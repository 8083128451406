import { AuctionBiddingStatus, EAuctionBiddingStatus } from '@nx/global-types'

export function shouldShowLiveUrl(
  allow_live_bid: boolean,
  lBidOnline: boolean,
  sBidOnlineStatus: EAuctionBiddingStatus,
  rowid: string
) {
  return (
    allow_live_bid &&
    lBidOnline &&
    sBidOnlineStatus === AuctionBiddingStatus.inProgress &&
    !!rowid
  )
}
