import getConfig from 'next/config'
import { useEffect, useRef, useState } from 'react'

import { useGetAuction, useGetLot } from '@nx/api/hooks'
import {
  AMConnectionStatus,
  EAMConnectionStatus,
  LostAMConnectionNotification,
} from '@nx/external/auction-mobility'
import { LoveIcon, LoveSelectedIcon } from '@nx/fire/assets'
import { FollowButton } from '@nx/fire/follow-button'
import { FollowType } from '@nx/get-user-follows'
import { AuctionType } from '@nx/global-types'
import { extractDataFromHTML } from '@nx/helpers'
import { SmartlingAttributes } from '@nx/smartling'
import { getTranslate } from '@nx/translations'
import { GTM_EVENTS, useDataLayer } from '@nx/utils/google-tag-manager'

import { AMWidget } from '@web/components/lot/AuctionMobility'
import { Price } from '@web/components/lot/Price'
import { useGetLoginStatus } from '@web/helpers/api'
import { populateUrl } from '@web/helpers/utils/urlBuilders/populateUrl'

import { LotCardItemProps } from '../LotCard/LotCard.types'
import {
  StyledAMWidgetContainer,
  StyledColumn,
  StyledContainer,
  StyledContentColumn,
  StyledDescription,
  StyledFireModal,
  StyledFollowButtonContainer,
  StyledImage,
  StyledImageContainer,
  StyledLotNumberAndSymbols,
  StyledLotPageLink,
  StyledTitle,
} from './LotCardBidModal.styles'
import translate from './LotCardBidModal.translate.json'

const { publicRuntimeConfig } = getConfig()
const { text } = getTranslate(translate)

export function LotCardBidModal({
  algoliaLotData,
  lotPageLink,
  setIsOpen,
}: LotCardBidModalProps) {
  const [amIsConnected, setAmIsConnected] = useState<boolean>(true)
  const gtmEventSent = useRef<boolean>(false)
  const dataLayer = useDataLayer()
  const userLoginStatus = useGetLoginStatus()
  const { data: lotData } = useGetLot({
    baseURL: publicRuntimeConfig.WEB_API_URL,
    lotId: algoliaLotData.lotId,
    auctionId: algoliaLotData.auctionId.toString(),
  })
  const { data: auctionData } = useGetAuction({
    baseURL: publicRuntimeConfig.WEB_API_URL,
    auctionId: algoliaLotData.auctionId.toString(),
  })

  /* istanbul ignore next */
  const handleAmConnection = (status: EAMConnectionStatus) => {
    if (
      status === AMConnectionStatus.disconnected ||
      status === AMConnectionStatus.errored
    ) {
      setAmIsConnected(false)
    } else if (status === AMConnectionStatus.connected) {
      setAmIsConnected(true)
    }
  }
  useEffect(() => {
    if (!gtmEventSent.current && lotData && auctionData) {
      dataLayer.push({
        event: GTM_EVENTS.bidNow,
        data: {
          lotId: `${algoliaLotData.auctionId}-${algoliaLotData.lotId}`,
          auctionId: algoliaLotData.auctionId,
          department: lotData.sale_info.sDepartment,
          location: auctionData.sVenue,
        },
      })
      gtmEventSent.current = true
    }
  }, [
    gtmEventSent,
    lotData,
    auctionData,
    dataLayer,
    algoliaLotData.lotId,
    algoliaLotData.auctionId,
  ])

  const getImageParams = (image: Exclude<LotCardItemProps['image'], null>) => {
    if ('imageURLParams' in image) {
      return image.imageURLParams || ''
    }

    if ('URLParams' in image) {
      return image.URLParams
    }

    return ''
  }

  const imageUrl = algoliaLotData.image?.url
    ? populateUrl(
        algoliaLotData.image.url,
        getImageParams(algoliaLotData.image),
        { width: 650 }
      )
    : ''

  return (
    <StyledFireModal isOpen={true} setIsOpen={setIsOpen}>
      <StyledContainer>
        <StyledColumn>
          <StyledImageContainer>
            <StyledImage
              src={imageUrl}
              width="100"
              height="0"
              alt={algoliaLotData.image?.caption || ''}
              $hasImage={Boolean(imageUrl)}
            />
          </StyledImageContainer>
        </StyledColumn>
        <StyledContentColumn>
          <StyledLotNumberAndSymbols
            lExhibition={algoliaLotData.isExhibition}
            iSaleLotNo={algoliaLotData.lotIdNumber}
            sSaleLotNoA={
              algoliaLotData.lotIdLetter === '0'
                ? ''
                : algoliaLotData.lotIdLetter
            }
            lotSymbols={algoliaLotData.symbols}
          />
          <StyledTitle
            {...SmartlingAttributes.noTranslate}
            dangerouslySetInnerHTML={{
              __html: extractDataFromHTML(
                algoliaLotData.styledDescription,
                'firstLine'
              ),
            }}
          />
          <StyledFollowButtonContainer>
            <FollowButton
              itemId={algoliaLotData.lotItemId}
              type={FollowType.lot}
              metadata={{
                auctionId: algoliaLotData.auctionId,
              }}
              baseURL={publicRuntimeConfig.WEB_API_URL}
              followIcons={[LoveSelectedIcon, LoveIcon]}
            />
          </StyledFollowButtonContainer>

          {
            /* istanbul ignore next */
            !amIsConnected && <LostAMConnectionNotification />
          }
          {auctionData && lotData && lotData.rowid && (
            <StyledAMWidgetContainer>
              <AMWidget
                key={`am-${lotData.rowid}-${userLoginStatus.data?.isLoggedIn}`}
                auction={auctionData}
                lot={lotData}
                options={{
                  showCountdown: auctionData.sSaleType === AuctionType.online,
                  isUserLoggedIn: userLoginStatus.data?.isLoggedIn,
                  hasHammerPrice: !!lotData.dHammerPrice,
                }}
                connectionStatusCallback={handleAmConnection}
              />
            </StyledAMWidgetContainer>
          )}

          <Price
            auctionType={algoliaLotData.auctionType}
            lotStatus={algoliaLotData.status}
            lNotPublishedResults={algoliaLotData.isResultPublished}
            dHammerPrice={algoliaLotData.hammerPrice}
            dHammerPremium={algoliaLotData.hammerPremium}
            lExhibition={algoliaLotData.isExhibition}
            lReferDept={algoliaLotData.isReferToDepartment}
            lWithoutReserve={algoliaLotData.isWithoutReserve}
            dEstimateLow={algoliaLotData.estimateLow}
            sCurrencySymbol={algoliaLotData.currencySymbol}
            dEstimateHigh={algoliaLotData.estimateHigh}
            currencyISO={algoliaLotData.currencyISO}
            GBPLowEstimate={algoliaLotData.GBPLowEstimate}
            GBPHighEstimate={algoliaLotData.GBPHighEstimate}
          />

          {lotData && (
            <StyledDescription
              dangerouslySetInnerHTML={{
                __html: extractDataFromHTML(lotData.sCatalogDesc, 'LotDesc'),
              }}
            />
          )}

          <StyledLotPageLink variant="ghost" href={lotPageLink}>
            {text('lotLink')}
          </StyledLotPageLink>
        </StyledContentColumn>
      </StyledContainer>
    </StyledFireModal>
  )
}

type LotCardBidModalProps = {
  algoliaLotData: LotCardItemProps
  lotPageLink: string
  setIsOpen: () => void
}
