import getConfig from 'next/config'

import { useGetUserRegistrations } from '@nx/api'

import { useGetLoginStatus } from '@web/helpers/api'

const { publicRuntimeConfig } = getConfig()

export function useUserRegistrations() {
  const { data: user } = useGetLoginStatus()

  return useGetUserRegistrations({
    baseUrl: publicRuntimeConfig.CLIENT_SERVICE_URL,
    clientNo: user.user.iClientNo,
  })
}
