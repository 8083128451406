import { toZonedTime } from 'date-fns-tz'
import { useRouter } from 'next/router'
import { MouseEventHandler, useEffect, useState } from 'react'

import {
  AuctionResponse,
  getAuction,
  useGetUserIsRegisteredForAuction,
} from '@nx/api'
import { useCheckUserLoginStatus } from '@nx/auth'
import { FireButton } from '@nx/fire/button'
import { openLogin } from '@nx/fire/login-modal'
import {
  AuctionBiddingStatus,
  AuctionType,
  EAuctionBiddingStatus,
  EAuctionStatus,
  EAuctionType,
  MaintenanceMode,
  SaleStatus,
} from '@nx/global-types'
import {
  buildAuctionRegistrationUrl,
  getLiveAuctionUrl,
  shouldShowLiveUrl,
} from '@nx/helpers'
import { getTranslate } from '@nx/translations'

import {
  RegisterActionsWrapper,
  RegisteredText,
  StyledAuctionRegistrationHelpText,
} from './AuctionRegistrationActions.styles'
import translate from './AuctionRegistrationActions.translate.json'

const { text } = getTranslate(translate)
let interval: number
const POLL_INTERVAL = 60_000
const MILLISECONDS_PER_HOUR = 60 * 60 * 1000

export function AuctionRegistrationActions({
  auction: {
    allow_live_bid,
    lExhibition,
    lRunOnAM,
    sSaleType,
    sSaleStatus,
    iSaleNo,
    rowid,
    lBidOnline,
    sBidOnlineStatus,
    dates,
  },
  apiUrl,
  baseAMUrl,
  baseUrl,
  maintenanceMode,
}: AuctionRegistrationHelpTextProps) {
  const [isMobileForAM, setIsMobileForAM] = useState(false)

  const [isLiveUrlVisible, setIsLiveUrlVisible] = useState(
    shouldShowLiveUrl(allow_live_bid, lBidOnline, sBidOnlineStatus, rowid)
  )

  const isRegisterButtonVisible = showRegisterButton({
    isExhibition: lExhibition,
    auctionType: sSaleType,
    auctionStatus: sSaleStatus,
    rowId: rowid,
    canBidOnline: lBidOnline,
    biddingStatus: sBidOnlineStatus,
  })

  const startDateTime = dates.start[0].date.datetime
  const localTimezone = dates.start[0].date.timezone.iana

  useEffect(() => {
    const isMobile = typeof window !== 'undefined' && window.screen.width < 1000

    isMobile && setIsMobileForAM(isMobile)
  }, [])

  useEffect(() => {
    if (interval) {
      window.clearInterval(interval)
    }
    const startDate = toZonedTime(startDateTime, localTimezone)
    let nowDt = toZonedTime(new Date(), localTimezone)
    let hoursDiff =
      (startDate.getTime() - nowDt.getTime()) / MILLISECONDS_PER_HOUR
    if (
      sSaleType === AuctionType.live &&
      sSaleStatus !== SaleStatus.finished &&
      hoursDiff <= 2
    ) {
      interval = window.setInterval(async () => {
        nowDt = toZonedTime(new Date(), localTimezone)
        hoursDiff =
          (startDate.getTime() - nowDt.getTime()) / MILLISECONDS_PER_HOUR

        const { allow_live_bid, lBidOnline, sBidOnlineStatus } =
          await getAuction(apiUrl, `${iSaleNo}`)
        const isSaleInProgress = shouldShowLiveUrl(
          allow_live_bid,
          lBidOnline,
          sBidOnlineStatus,
          rowid
        )
        setIsLiveUrlVisible(hoursDiff <= 1 || isSaleInProgress)
        if (!isSaleInProgress) {
          window.clearInterval(interval)
        }
      }, POLL_INTERVAL)
    }
    return () => window.clearInterval(interval)
  }, [
    localTimezone,
    startDateTime,
    iSaleNo,
    sSaleType,
    sSaleStatus,
    rowid,
    apiUrl,
  ])

  return isLiveUrlVisible || isRegisterButtonVisible ? (
    <StyledAuctionRegistrationHelpText>
      {(isLiveUrlVisible || isRegisterButtonVisible) && (
        <RegisterActionsWrapper>
          {isLiveUrlVisible && (
            <FireButton
              variant="primary"
              href={getLiveAuctionUrl({
                rowid,
                lRunOnAM,
                saleId: iSaleNo,
                isMobile: isMobileForAM,
                baseAMUrl,
              })}
            >
              {text('auctionRegistrationHelpText.watchLive')}
            </FireButton>
          )}
          {isRegisterButtonVisible && (
            <RegisterButton
              rowId={rowid}
              auctionId={iSaleNo}
              showLiveUrl={isLiveUrlVisible}
              baseUrl={baseUrl}
              apiUrl={apiUrl}
              baseAMUrl={baseAMUrl}
              maintenanceModeEnabled={
                maintenanceMode === MaintenanceMode.partial
              }
            />
          )}
        </RegisterActionsWrapper>
      )}
    </StyledAuctionRegistrationHelpText>
  ) : null
}

export const showRegisterButton = ({
  isExhibition,
  auctionType,
  auctionStatus,
  rowId,
  canBidOnline,
  biddingStatus,
}: {
  isExhibition: boolean | undefined
  auctionType: EAuctionType
  auctionStatus?: EAuctionStatus
  rowId: string
  canBidOnline: boolean
  biddingStatus?: EAuctionBiddingStatus
}) => {
  if (
    isExhibition ||
    auctionType === AuctionType.private ||
    !auctionStatus ||
    auctionStatus === SaleStatus.finished ||
    auctionStatus === SaleStatus.new ||
    !biddingStatus ||
    biddingStatus === AuctionBiddingStatus.ended ||
    !rowId
  ) {
    return false
  } else {
    return canBidOnline || auctionType === AuctionType.online
  }
}

const RegisterButton = ({
  rowId,
  auctionId,
  showLiveUrl,
  baseUrl,
  apiUrl,
  baseAMUrl,
  maintenanceModeEnabled,
}: RegisterButtonProps) => {
  const router = useRouter()
  const returnUrl = buildAuctionRegistrationUrl({
    rowId,
    currentPath: router.asPath,
    baseUrl,
    baseAMUrl,
  })
  const { data: user } = useCheckUserLoginStatus({
    apiUrl,
  })

  const { userRegistered, approvedToBid } = useGetUserIsRegisteredForAuction({
    auctionId: auctionId,
    userId: user.user.iClientNo,
    baseUrl: apiUrl,
  })

  const userIsApprovedToBid =
    approvedToBid === undefined
      ? Boolean(user.user.approved_to_bid)
      : approvedToBid

  if (user.isLoggedIn) {
    if (!userRegistered) {
      return maintenanceModeEnabled ? null : (
        <FireButton
          href={returnUrl}
          variant={showLiveUrl ? 'secondary' : 'primary'}
        >
          {text('auctionRegistrationHelpText.registerToBid')}
        </FireButton>
      )
    } else {
      if (userIsApprovedToBid) {
        return (
          <RegisteredText $level="M">
            {text('auctionRegistrationHelpText.registered')}
          </RegisteredText>
        )
      } else {
        return (
          <FireButton
            variant="label"
            size="M"
            href="/how-to-buy/how-to-get-approved-to-bid/"
          >
            {text('auctionRegistrationHelpText.registrationPending')}
          </FireButton>
        )
      }
    }
  } else {
    const showLogin: MouseEventHandler<HTMLAnchorElement> = (e) => {
      e.preventDefault()
      openLogin('LOGIN', returnUrl)
    }

    return maintenanceModeEnabled ? null : (
      <FireButton
        href={`/login?next=${returnUrl}`}
        variant={showLiveUrl ? 'secondary' : 'primary'}
        onClick={showLogin}
      >
        {text('auctionRegistrationHelpText.registerToBid')}
      </FireButton>
    )
  }
}

interface AuctionRegistrationHelpTextProps {
  auction: AuctionResponse
  apiUrl: string
  baseAMUrl: string
  baseUrl: string
  maintenanceMode?: MaintenanceMode
}

interface AuctionRegistrationHelpTextProps {
  auction: AuctionResponse
  apiUrl: string
  baseAMUrl: string
}

interface RegisterButtonProps {
  rowId: string
  auctionId: number
  showLiveUrl: boolean
  baseUrl: string
  apiUrl: string
  baseAMUrl: string
  maintenanceModeEnabled: boolean
}
