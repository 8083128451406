import styled, { css } from 'styled-components'

import { Label } from '@nx/fire/typography'

export const StyledAuctionRegistrationHelpText = styled.div`
  display: grid;
  gap: 15px;
`

export const RegisterActionsWrapper = styled.div`
  display: flex;
  gap: 20px 40px;
  flex-wrap: wrap;
  align-items: center;

  ${({ theme }) => css`
    ${theme.media.up.lg} {
      gap: 0 60px;
    }
  `}
`

export const RegisteredText = styled(Label)`
  color: ${({ theme: { colours } }) => colours.secondary.secondary};
`
