import styled from 'styled-components'

import { Body } from '@nx/fire/typography'
import { ELotStatus, LotStatus } from '@nx/global-types'
import { getTranslate } from '@nx/translations'

import translate from './LotCardStatus.translate.json'

const { text } = getTranslate(translate)

function LotCardStatus({
  status,
  isAmended,
  isWithoutReserve,
}: LotCardStatusProps) {
  const isWithdraw = status?.toUpperCase() === LotStatus.withdrawn

  return isWithdraw || isAmended || isWithoutReserve ? (
    <LotCardStatusContainer>
      {isWithdraw && (
        <StyledLotCardStatusLabel as="div" $level="M">
          {text('lotCardStatus.withdrawn')}
        </StyledLotCardStatusLabel>
      )}
      {isAmended && (
        <StyledLotCardStatusLabel as="div" $level="M">
          {text('lotCardStatus.amended')}
        </StyledLotCardStatusLabel>
      )}
      {isWithoutReserve && (
        <StyledLotCardStatusLabel as="div" $level="M">
          {text('lotCardStatus.withoutReserve')}
        </StyledLotCardStatusLabel>
      )}
    </LotCardStatusContainer>
  ) : null
}

export const LotCardStatusContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  margin-top: 10px;
`

export const StyledLotCardStatusLabel = styled(Body)`
  text-transform: uppercase;
`

export interface LotCardStatusProps {
  status: ELotStatus
  isAmended: boolean
  isWithoutReserve: boolean
}

export default LotCardStatus
