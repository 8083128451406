import axios, { AxiosError } from 'axios'

export function isAuthenticatedAxiosError<T>(
  error: unknown
): error is AuthenticatedAxiosClientError<T> {
  return (
    typeof error === 'object' &&
    !!error &&
    'error' in error &&
    'status' in error &&
    'message' in error &&
    axios.isAxiosError(error.error)
  )
}

interface AuthenticatedAxiosClientError<T> {
  status: number
  message: string
  error: AxiosError<T>
}
