import Cookies from 'js-cookie'

import { createAxiosClient, isAuthenticatedAxiosError } from '@nx/axios-client'
import { BonhamsCookies } from '@nx/global-types'
import { logger } from '@nx/utils/logger'

import {
  RegisteredForAuction,
  RegisteredForAuctionResponse,
} from '../getUserIsRegisteredForAuction/userIsRegisteredForAuction.types'

export async function getUserRegistrations(
  clientNo: number,
  baseUrl: string,
  accessTokenSSR?: BonhamsCookies['bonhams_access'],
  refreshTokenSSR?: BonhamsCookies['bonhams_token']
): Promise<RegisteredForAuction[] | null> {
  if (!accessTokenSSR && !Cookies.get('bonhams_access')) {
    return null
  }

  const authenticatedAxiosClient = createAxiosClient({
    accessTokenSSR,
    refreshTokenSSR,
    baseURL: baseUrl,
  })

  try {
    const response =
      await authenticatedAxiosClient.get<RegisteredForAuctionResponse>(
        `/api/client/${clientNo}/registrations/`
      )

    return response.data
  } catch (error) {
    if (isAuthenticatedAxiosError(error)) {
      logger.error(
        error,
        `Error on ${authenticatedAxiosClient.defaults.baseURL}/api/client/${clientNo}/registrations/ - status code: ${error.status}`
      )
    }

    return null
  }
}
