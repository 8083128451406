import styled from 'styled-components'

export const PriceContainer = styled.span`
  white-space: nowrap;
`

export const EstimationPriceContainer = styled.span`
  display: block;
`

export const ConvertedEstimationPriceContainer = styled(
  EstimationPriceContainer
)`
  color: ${({ theme }) => theme.colours.grey['75']};
`
