import { AxiosError, AxiosResponse } from 'axios'

import { NotFoundError } from '@nx/helpers'

export class ApiError extends Error {}

export class AuthError extends Error {}

export function getAPIError(error: AxiosError) {
  let response: AxiosResponse

  if (error.response) {
    response = error.response
  } else if ('error' in error) {
    // Handle authenticated axios client errors
    response = (error.error as AxiosError).response as AxiosResponse
  } else {
    return new ApiError(error.message)
  }

  const status = response?.status

  const { baseURL, url, params } = response.config
  const finalUrl = new URL(url || '', baseURL)
  if (params) {
    finalUrl.search = new URLSearchParams(params).toString()
  }

  if (response && status === 403) {
    return new AuthError(
      `${status.toString()} ${response.statusText} - ${finalUrl}`
    )
  }

  if (response && status === 404) {
    return new NotFoundError(
      `${status.toString()} ${response.statusText} - ${finalUrl.toString()}`
    )
  }

  return new ApiError(status?.toString())
}
