import { AxiosRequestConfigWithRetry, axiosInstance } from '@nx/fetch'
import { NotFoundError } from '@nx/helpers'

import { getAPIError } from '../../../helpers'
import { LotResponse } from '../types'

export async function getLot(
  baseURL: string,
  auctionId: string,
  lotId: string,
  options?: AxiosRequestConfigWithRetry
) {
  if (!/^\d+$/.test(auctionId)) {
    throw new NotFoundError('auctionId must be an integer')
  }

  return await axiosInstance
    .get<LotResponse>(`/api/v2/sale/${auctionId}/lot/${lotId}/`, {
      baseURL,
      ...options,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw getAPIError(error)
    })
}
