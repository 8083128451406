import { cleanDoubleSlashes } from '@nx/populate-url'

import { buildLegacyAuctionPath } from '../buildAuctionPath/buildAuctionPath'

export const getLiveAuctionUrl = ({
  rowid,
  lRunOnAM,
  saleId,
  isMobile = false,
  baseAMUrl,
}: {
  rowid: string
  lRunOnAM: boolean
  saleId: number
  isMobile?: boolean
  baseAMUrl: string
}) =>
  cleanDoubleSlashes(
    lRunOnAM
      ? `${baseAMUrl}live-auction${isMobile ? '-mobile' : ''}/${rowid}`
      : `${buildLegacyAuctionPath(saleId)}/live/`
  )

export type BuildAuctionRegistrationUrlProps = {
  rowId: string
  currentPath: string
  baseUrl: string
  baseAMUrl: string
}

export const buildAuctionRegistrationUrl = ({
  rowId,
  currentPath,
  baseUrl,
  baseAMUrl,
}: BuildAuctionRegistrationUrlProps) => {
  const finalPath = encodeURIComponent(baseUrl + currentPath)
  return cleanDoubleSlashes(
    `${baseAMUrl}register-to-bid/${rowId}?return_to_widget=${finalPath}`
  )
}
