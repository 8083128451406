import styled, { css } from 'styled-components'

import { Body } from '@nx/fire/typography'
import {
  AuctionType,
  EAuctionType,
  ELotStatus,
  LotStatus,
} from '@nx/global-types'
import { getTranslate } from '@nx/translations'

import { EstimationPrice, PremiumPrice } from '@web/components/common/LotPrice'

import translate from './LotCardPrice.translate.json'

const { text } = getTranslate(translate)

export function LotCardPrice({
  status,
  auctionType,
  isResultPublished,
  isExhibition,
  isReferToDepartment,
  currencySymbol,
  currencyISO,
  hammerPrice,
  hammerPremium,
  estimateLow,
  estimateHigh,
  GBPLowEstimate,
  GBPHighEstimate,
  highlightWon = false,
  ...props
}: LotCardPriceProps) {
  const actionText = highlightWon
    ? text('lotCardPrice.won')
    : text('lotCardPrice.sold')

  const isPrivate = auctionType?.toUpperCase() === AuctionType.private

  let content: React.ReactNode = null

  if (!isPrivate && status?.toUpperCase() === LotStatus.sold) {
    content = !isResultPublished ? (
      <div>{actionText}</div>
    ) : (
      <PremiumPrice
        hammerPrice={hammerPrice}
        hammerPremium={hammerPremium}
        currencySymbol={currencySymbol}
        highlightWon={highlightWon}
      />
    )
  } else if (isPrivate) {
    content = <div>{text('lotCardPrice.private')}</div>
  } else if (isExhibition) {
    content = null
  } else if (isReferToDepartment) {
    content = <div>{text('lotCardPrice.referToDept')}</div>
  } else {
    content = (
      <EstimationPrice
        auctionType={auctionType}
        sLotStatus={status}
        currencySymbol={currencySymbol}
        estimateLow={estimateLow}
        estimateHigh={estimateHigh}
        GBPLowEstimate={GBPLowEstimate}
        GBPHighEstimate={GBPHighEstimate}
        currencyISO={currencyISO}
        withEstimate={false}
      />
    )
  }

  return (
    <LotCardPriceContainer
      $level="M"
      data-testid="lot-price"
      $unsold={
        status === LotStatus.unsold || status === LotStatus.withoutResults
      }
      as="div"
      $highlightWon={highlightWon}
      {...props}
    >
      {content}
    </LotCardPriceContainer>
  )
}

export const LotCardPriceContainer = styled(Body)<{
  $unsold: boolean
  $highlightWon: boolean
}>`
  ${({ $unsold, $highlightWon, theme }) => css`
    margin-top: 10px;

    ${$unsold &&
    css`
      color: ${theme.colours.primary.charcoal};
    `}

    ${$highlightWon &&
    css`
      ${theme.fontStyles.sourceSans.bold}
      color: ${theme.colours.status.success};
    `}
  `}
`

export interface LotCardPriceProps {
  status: ELotStatus
  auctionType: EAuctionType
  isResultPublished: boolean
  isExhibition: boolean
  isReferToDepartment: boolean
  currencySymbol: string
  currencyISO: string
  hammerPrice: number
  hammerPremium: number
  estimateLow: number
  estimateHigh: number
  GBPLowEstimate: number
  GBPHighEstimate: number
  highlightWon?: boolean
}
