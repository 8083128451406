import { css, styled } from 'styled-components'

import { FireButton } from '@nx/fire/button'
import { FireImage } from '@nx/fire/image'
import { LotNumberAndSymbols } from '@nx/fire/lot-number-and-symbols'
import {
  FireModal,
  StyledCloseButtonContainer,
  StyledHeader,
} from '@nx/fire/modal'

export const StyledFireModal = styled(FireModal)`
  ${({ theme }) => css`
    max-width: 900px;
    padding: 0;
    width: 100%;
    overflow: clip;

    ${StyledHeader} {
      height: 0;
      overflow: visible;
      min-height: 0;
      padding: 0;

      ${StyledCloseButtonContainer} {
        right: 0;
        background: transparent;

        ${theme.media.up.md} {
          background: ${theme.colours.grey['15']};
        }

        ${theme.media.up.lg} {
          right: 13px;
        }
      }
    }
  `}
`

export const StyledContainer = styled.div`
  display: grid;
  height: 100vh;
  grid-template-rows: 30% 70%;

  ${({ theme }) => theme.media.up.md} {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    overflow: hidden;
  }
`

export const StyledColumn = styled.div`
  padding: 15px;

  ${({ theme }) => theme.media.up.md} {
    height: 100%;
    padding: 30px;
    position: relative;
    overflow: hidden;
  }
`

export const StyledContentColumn = styled(StyledColumn)`
  background: ${({ theme }) => theme.colours.grey['15']};
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ theme }) => theme.media.up.md} {
    overflow: scroll;
  }
`

export const StyledImageContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`

export const StyledImage = styled(FireImage)<{
  $hasImage: boolean
}>`
  ${({ theme: { media, colours }, $hasImage }) => css`
    background-color: ${!$hasImage ? colours.primary.white : null};
    height: 100%;
    object-fit: contain;
    object-position: left;
    padding: ${!$hasImage ? '17px 5%' : null};
    position: relative;
    width: auto;
    max-width: 100%;

    ${media.up.md} {
      height: unset;
      max-height: 500px;
      padding: ${!$hasImage ? '65px 5%' : 0};
      width: 100%;
    }
  `}
`

export const StyledLotNumberAndSymbols = styled(LotNumberAndSymbols)`
  * {
    cursor: default;
    text-decoration: none !important;
  }
`

export const StyledTitle = styled.div`
  margin-top: 1rem;

  .firstLine {
    ${({ theme }) => theme.typography.heading.heading5}
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

export const StyledFollowButtonContainer = styled.div`
  padding-top: 10px;
`

export const StyledAMWidgetContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colours.grey['45']};
  border-top: 1px solid ${({ theme }) => theme.colours.grey['45']};
  margin-block: 20px;
  padding-bottom: 20px;
`

export const StyledDescription = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;

  b {
    ${({ theme }) => theme.fontStyles.sourceSans.medium}
  }
`

export const StyledLotPageLink = styled(FireButton)`
  ${({ theme }) => css`
    padding-block: 30px;
    text-decoration: underline;

    ${theme.media.up.md} {
      padding-bottom: 0;
    }
  `}
`
