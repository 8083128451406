import { useQuery } from '@tanstack/react-query'

import { getLot } from '../../../libs/lot'

type UseGetLotProps = {
  baseURL: string
  auctionId: string
  lotId: string
}

export function useGetLot({ baseURL, auctionId, lotId }: UseGetLotProps) {
  return useQuery({
    queryKey: ['lot', lotId],
    queryFn: () => getLot(baseURL, auctionId, lotId),
  })
}
