import { useQuery } from '@tanstack/react-query'

import { getAuction } from '../../../libs/auction'

type useGetAuctionProps = {
  baseURL: string
  auctionId: string
}

export function useGetAuction({ baseURL, auctionId }: useGetAuctionProps) {
  return useQuery({
    queryKey: ['auction', auctionId],
    queryFn: () => getAuction(baseURL, auctionId),
  })
}
