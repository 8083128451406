import getConfig from 'next/config'
import { MouseEventHandler } from 'react'

import { LoveIcon, LoveSelectedIcon } from '@nx/fire/assets'
import { FollowButton } from '@nx/fire/follow-button'
import { FollowType, useFollow } from '@nx/get-user-follows'
import {
  AuctionBiddingEngine,
  AuctionLot,
  EAuctionBiddingEngine,
  ImageValue,
  LegacyImageValue,
} from '@nx/global-types'
import { SmartlingAttributesType } from '@nx/smartling'

import { populateUrl } from '@web/helpers/utils/urlBuilders/populateUrl'

import {
  StyledLotCardImage,
  StyledLotCardImageContainer,
  StyledLotCardImageLink,
} from './LotCardImage.styles'

const { publicRuntimeConfig } = getConfig()

function LotCardImage({
  image,
  lotItemId,
  auctionId,
  title,
  href,
  lotClickCallback,
  isFollowButtonDisplayed = true,
  biddingEngine,
  size = 'M',
  ...imageProps
}: LotCardImageProps) {
  const { isFollowing } = useFollow({
    type: FollowType.lot,
    itemId: lotItemId,
    metadata: { auctionId },
    baseURL: publicRuntimeConfig.WEB_API_URL,
  })

  const imageDimensions =
    image && 'height' in image
      ? {
          height: image.height,
          width: image.width,
        }
      : undefined

  const getImageParams = (image: LegacyImageValue | ImageValue) => {
    if ('imageURLParams' in image) {
      return image.imageURLParams || ''
    }

    if ('URLParams' in image) {
      return image.URLParams
    }

    return ''
  }

  const additionalParameters:
    | Record<string, string | number | boolean>
    | string = [AuctionBiddingEngine.AM, AuctionBiddingEngine.A3].includes(
    biddingEngine as AuctionBiddingEngine
  )
    ? { width: 650 }
    : ''

  const imageUrl = image?.url
    ? populateUrl(image.url, getImageParams(image), additionalParameters)
    : ''

  return (
    <StyledLotCardImageContainer
      $hasImage={Boolean(imageUrl)}
      $isFollowing={isFollowing}
      $size={size}
    >
      {isFollowButtonDisplayed && (
        <FollowButton
          type={FollowType.lot}
          itemId={lotItemId}
          title={title}
          variant="simple"
          metadata={{ auctionId }}
          baseURL={publicRuntimeConfig.WEB_API_URL}
          followIcons={[LoveSelectedIcon, LoveIcon]}
        />
      )}
      <StyledLotCardImageLink
        href={href}
        tabIndex={-1}
        onClick={lotClickCallback}
      >
        <StyledLotCardImage
          src={imageUrl}
          width={imageDimensions?.width ? imageDimensions.width : '100'}
          height={imageDimensions?.height ? imageDimensions.height : '0'}
          alt={image?.caption || ''}
          $hasImage={Boolean(imageUrl)}
          $size={size}
          {...imageProps}
        />
      </StyledLotCardImageLink>
    </StyledLotCardImageContainer>
  )
}

export type LotCardImageProps = Pick<AuctionLot, 'image' | 'lotItemId'> & {
  href: string
  lotClickCallback?: MouseEventHandler<HTMLAnchorElement>
  isFollowButtonDisplayed?: boolean
  biddingEngine: EAuctionBiddingEngine
  title: string
  auctionId?: number | string
  size?: 'S' | 'M'
} & SmartlingAttributesType['noTranslate']

export default LotCardImage
