import { Body, Heading, Label } from '@nx/fire/typography'
import styled, { css } from 'styled-components'

export const StyledLotCardTitleStatus = styled(Label)`
  color: ${({ theme }) => theme.colours.grey['90']};
  margin-bottom: 0.5rem;
`

export const LotCardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const LotSymbol = styled.abbr`
  text-decoration: none;
`

export const StyledLotCardTitleHeading = styled(Body)<{ $isDivider?: boolean }>`
  color: ${({ theme }) => theme.colours.primary.charcoal};
  font-style: italic;
  margin-bottom: 0.8rem;
  word-break: break-word;

  ${({ $isDivider, theme }) =>
    $isDivider &&
    css`
      &::before {
        content: '';
        display: block;
        width: 11px;
        border-top: 1px solid ${theme.colours.grey[45]};
        margin-bottom: 4px;
        margin-top: -0.4rem;
      }
    `}
`

export const StyledLotCardTitleContainer = styled(Heading)`
  display: inline;
  font-weight: 500;
  word-break: break-word;

  sup {
    ${({ theme }) => theme.typography.body.XS}
    vertical-align: top;
  }

  i,
  em {
    font-style: italic;
  }

  .firstLine,
  .secondLine {
    ${({ theme }) => theme.fontStyles.sangbleu.medium}
    display: inline;
  }

  .firstLine:after {
    content: ' ';
  }

  .otherLine {
    ${({ theme }) => theme.fontStyles.sourceSans.regular}
    display: block;
  }
`
